import React, { useEffect } from 'react';
import './card.css';
import mascota from '../../assets/LEON2.png';
import Card from './Card';

const ServicesSection = (props) => {
    const { dataServices } = props;
    //console.log(dataServices)
    //useEffect(() => console.log(dataServices), []);
    return (
        <section id='cardContainer'>
            <h2>SERVICIOS</h2>
            <article className='cardsSection'>
                <img className='pet' src={mascota} alt='mascota' />
                {
                    dataServices.map((item, index) => {
                        return <Card key={index} href="#" title={item.title} image={item.image} />
                        //return <Card key={index} href={item.href} title={item.title} image={item.image} />
                    })
                }
            </article>
        </section>
    );
}

export default ServicesSection;