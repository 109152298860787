import React, { useEffect, useState } from "react";
import './detail.css';
import mascota from '../../assets/LEON1Flip.png';
import { servicesDetail } from '../../data/services';

const DetailService = (props) => {
    const { id } = props;
    const detail = servicesDetail[id];
    const [showModal, setShowModal] = useState(false);
    const [imgModal, setImgModal] = useState('')

    return (
        <main>
            <div className="content-service">
                <h1 className="title">{detail.title}</h1>
                {
                    detail.images.map((item, index) => {
                        return <img key={index} alt='example' src={item} className='serviceImg' onClick={() => {
                            setImgModal(item);
                            setShowModal(true);
                        }} />
                    })
                }
                <Description title={detail.title} detail={detail.detail} />
                <img src={mascota} alt='mascota' id="mascota" />
            </div>
            <div className="content-service content-price">
                {
                    detail.prices.map((item, index) => {
                        return <Prices key={index} title={item.title} description={item.description} price={item.price} />
                    })
                }
            </div>
            {showModal && <Modal image={imgModal} closeModal={() => setShowModal(false)}/>}
        </main>
    );
}
export default DetailService;

const Prices = (props) => {
    const { title, description, price } = props;
    return (
        <div className="pricing">
            <h2>{title}</h2>
            <p>{description}</p>
            <h3>{price}</h3>
        </div>
    );
}

const Description = (props) => {
    return (
        <div className="description">
            <h1>{props.title}</h1>
            <p>{props.detail}</p>
        </div>
    );
}

const Modal = (props) => {
    return (
        <div className="modal">
            <span className='close' onClick={props.closeModal}>&times;</span>
            <img className="modal-content" id="img01" alt="example" src={props.image} />
        </div>
    );
}