import 'react-slideshow-image/dist/styles.css'
import React from 'react';
import { Slide } from 'react-slideshow-image';
import slide0 from '../assets/slide-0.jpg'
import slide1 from '../assets/slide-1.png'
import slide2 from '../assets/slide-2.jpeg'
import slide3 from '../assets/slide-3.jpeg'
import slide4 from '../assets/slide-4.jpeg'
import slide5 from '../assets/slide-5.jpeg'

const images = [slide0,slide1, slide2, slide3, slide4,slide5];

const Properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    scale: 0.4,
    arrows: true,
};

const Slider = () => {

    return (
        <div className='slide-container'>
                <Slide {...Properties} >
                    {
                        images.map((image, index) => (
                            <div key={index} className='each-slide'>
                                <img src={image} alt='slide'/>
                            </div>
                        ))
                    }
                </Slide>
        </div>
    );
}

export default Slider;
