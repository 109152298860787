import React, { useState } from 'react';
import img_logo1 from "../../assets/logoSinLetras21.png";
import img_logo2 from "../../assets/insigniaMod2.png";
import img_toggle from '../../assets/lista.png';
import './nav.css';
import MenuOpcion from './MenuOpcion';
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';



const Navegacion = (props) => {
    const { whereIam } = props;
    const [isToggle, setIsToggle] = useState(false);
    const navigate = useNavigate();

    const onToggle = () => {
        // Lógica para cambiar el estado de isToggle
        setIsToggle(!isToggle);
    }
    const scrollToTop = () => {
        //Verifica si estamos en home solo sube , si no manda a home.
        whereIam==="home" ?
            window.scrollTo({
                top: 0,
            behavior: 'smooth' // Hace que el desplazamiento sea suave
            }): navigate('/'); 
        };

    return (
        <nav className='menu'>
            <div id="Home"></div>
            <a href="/" className='logoImg' >
                <img src={img_logo1} alt='logoImg' className='menu_logoImagen' />
            </a>
            <a href="/" className='logoTxt'>
                <img src={img_logo2} alt='logoTxt' className='menu_logoTexto' />
            </a>
            <img src={img_toggle} id="menu_toggle" className='menu_toggle' alt="toggle" onClick={onToggle} />
            <div  className={isToggle ? "menu_item active menu_item_right" : 'menu_item menu_item_right' }>
                <a href='#Home' className='link-nav' onClick={scrollToTop}>Inicio</a>
            </div>
            <MenuOpcion active={isToggle} name="Servicios" selected={whereIam === 'service'}
                innerElement={
                    <div className='submenu'>
                        <a href='#'>Mascota / Personaje</a>
                        <a href='#'>Logotipo Profesional</a>
                        <a href='#'>Logotipo Cartoon</a>
                        <a href='#'>Tarjeta Presentación</a>
                        <a href='#'>Volantes / Flyers</a>
                        <a href='#'>Recetario Médico</a>
                    </div>
                    /*<div className='submenu'>
                        <a href='/services/mascota'>Mascota / Personaje</a>
                        <a href='/services/logotipo'>Logotipo Profesional</a>
                        <a href='/services/cartoon'>Logotipo Cartoon</a>
                        <a href='/services/tarjeta'>Tarjeta Presentación</a>
                        <a href='/services/volante'>Volantes / Flyers</a>
                        <a href='/services/recetamedica'>Recetario Médico</a>
                    </div>*/
                } />
            <div className={isToggle ? "menu_item active" : 'menu_item' }>
                <Link to='#contacto' className='link-nav'>Contacto</Link>
            </div>
            <MenuOpcion active={isToggle} href="/" name="Faqs" selected={whereIam === 'about'} />
        </nav>
    );
}


export default Navegacion;