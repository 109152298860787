import { Link } from "react-router-dom";
const MenuOpcion = (props) => {
    const {
        name, href,
        active = false,
        right = false,
        selected = false,
        innerElement = '',
    } = props;

    let classes = active ? "active " : ' '; 
    classes += right ? "menu_item_right  " : ' '; 
    classes += selected ? "menu_item_actual  " : ''; 
    return (
        <div className={"menu_item " + classes}>
        <Link to={{pathname:href}}
                className="link-nav">
                {name}
            </Link>
            {innerElement}
        </div>
    );
}

export default MenuOpcion;