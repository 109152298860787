import React from "react";
import { useParams } from 'react-router-dom';
import Navegacion from './components/Navegacion';
import './App.css';
import Foooter from './components/Foooter';
import DetailService from "./components/DetailService";
const Services = () => {
    const { mewId } = useParams();
    return (
        <div className="App">
            <Navegacion whereIam='service'/>
            <DetailService id={mewId} />
            <Foooter />
        </div>
    )
}

export default Services;