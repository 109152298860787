import Navegacion from './components/Navegacion';
import './App.css';
import Foooter from './components/Foooter';
import { dataCards } from './data/services';
import ServicesSection from './components/ServicesSection';
import Slider from './components/Slider';

function App() {
  return (
    <div className="App">
      <Navegacion whereIam='home'/>
      <Slider />
      <ServicesSection dataServices={dataCards} />
      <Foooter />
    </div>
  );
}

export default App;
