import React from "react";
import './footer.css';
import Contacto from '../Contacto';


const Foooter = () => {
    return (
        <footer>
            <Contacto />
            <section id="copyright">
                <h4>&copy; {new Date().getFullYear()} Intellegnium Diseñadores. Todos los derechos reservados.</h4>
            </section>
        </footer>
    )
}

export default Foooter;