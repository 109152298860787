import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import Services from "./Services";

const RouteSwitch = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/services/:mewId" element={<Services />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RouteSwitch;