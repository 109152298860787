import React from 'react';

const Card = ({href,title,image}) =>{
    return(
        <div className='card'>
            <a href={href}>
                <div>
                    <h3 className={title}>{title}</h3>
                    <img className='card-image' src={image} alt={title} />
                </div>
            </a>
        </div>
    );
}

export default Card;