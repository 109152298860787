//import example from '../assets/logo-insignia.png';
import example_logo from '../assets/Logo.jpg';
import example_recetas from '../assets/Receta.jpg';
import example_tarjetas from '../assets/Tarjetas.jpg';
import image_ilustraciones from '../assets/Ilustraciones.jpg';
import image_mascota from '../assets/Mascota.jpg';
import image_cartoon from '../assets/LogoCartoon.jpg';
import image_volante from '../assets/Volante.jpg';
//import image_diploma from '../assets/Diploma.jpg';
import image_etiqueta from '../assets/Etiqueta.jpg';
//import image_cv from '../assets/CV.jpg';
import image_membretada from '../assets/HojaMembretada.jpg';
import image_portadaFace from '../assets/PortadaFacebook.jpg';
import image_menu from '../assets/Menu.jpg';
import image_notasRemision from '../assets/NotasRemision.jpg';
//import image_sello from '../assets/Sello.jpg';
//import image_senaletica from '../assets/Senaletica.jpg';
import image_triptico from '../assets/Triptico.jpg';
import image_portadaDisco from '../assets/PortadaDisco.jpg';


const dataCards = [
    {
        title: 'ILUSTRACIONES',
        image: image_ilustraciones,
        href: '/services/ilustraciones'
    },
    {
        title: 'MASCOTAS',
        href: '/services/mascota',
        image: image_mascota,
    },
    {
        title: 'LOGOTIPO',
        image: example_logo,
        href: '/services/logotipo'
    },
    {
        title: 'LOGO CARTOON',
        image: image_cartoon,
        href: '/services/cartoon'
    },
    {
        title: 'VOLANTE',
        image: image_volante,
        href: '/services/volante'
    },
    /*{
        title: 'DIPLOMA',
        image: image_diploma,
        href: '/services/diploma'
    },*/
    {
        title: 'ETIQUETA',
        image: image_etiqueta,
        href: '/services/etiqueta'
    },
     /*{{
        title: 'CURRICULUM VITAE',
        image: image_cv,
        href: '/services/cv'
    },*/
    {
        title: 'HOJA MEMBRETADA',
        image: image_membretada,
        href: '/services/membretada'
    },
    {
        title: 'PORTADA DE FACEBOOK',
        image: image_portadaFace,
        href: '/services/portadaface'
    },
    {
        title: 'MENÚ',
        image: image_menu,
        href: '/services/menu'
    },
    {
        title: 'NOTA DE REMISIÓN',
        image: image_notasRemision,
        href: '/services/remision'
    },
     /*{{
        title: 'SELLO',
        image: image_sello,
        href: '/services/sello'
    },
    {
        title: 'SEÑALÉTICA',
        image: image_senaletica,
        href: '/services/senaletica'
    },*/
    {
        title: 'RECETA  MÉDICA',
        image: example_recetas,
        href: '/services/recetamedica'
    },
    {
        title: 'TARJETA DE PRESENTACIÓN',
        image: example_tarjetas,
        href: '/services/tarjeta'
    },
    {
        title: 'TRÍPTICO',
        image: image_triptico,
        href: '/services/triptico'
    },
    {
        title: 'PORTADA PARA DISCOS',
        image: image_portadaDisco,
        href: '/services/discos'
    }
];

const servicesDetail = {
    mascota: {
        title: 'Mascota',
        detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        images: [
            'https://upload.wikimedia.org/wikipedia/commons/5/5d/Making_a_Nova.jpg',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Young_child_and_an_old_person_are_petting_grey_cute_cat_-_51622977008.jpg/220px-Young_child_and_an_old_person_are_petting_grey_cute_cat_-_51622977008.jpg',
        ],
        prices: [
            {
                title: "Default Product 0",
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                price: '$100.00'
            },
            {
                title: "Default Product 1",
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                price: '$400.00'
            },
            {
                title: "Product 2",
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                price: '$150.00'
            },
            {
                title: "Product 4",
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                price: '$150.00'
            },
            {
                title: "Product 223",
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                price: '$150.00'
            }
        ]
    },
    logotipo: {
        title: 'Logotipo',
        detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        images: [
            'https://upload.wikimedia.org/wikipedia/commons/5/5d/Making_a_Nova.jpg',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Young_child_and_an_old_person_are_petting_grey_cute_cat_-_51622977008.jpg/220px-Young_child_and_an_old_person_are_petting_grey_cute_cat_-_51622977008.jpg',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/001_Tacos_de_carnitas%2C_carne_asada_y_al_pastor.jpg/310px-001_Tacos_de_carnitas%2C_carne_asada_y_al_pastor.jpg',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/Tacos_de_carne_asada_con_guacamole_y_cilantro.png/230px-Tacos_de_carne_asada_con_guacamole_y_cilantro.png',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Tacos_dorados.jpg/220px-Tacos_dorados.jpg',
        ],
        prices:[
            {
                title: "Default LOGOS 0",
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                price: '$100,000,000.99'
            }
        ],
    },
    cartoon: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    tarjeta: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    volante: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    recetamedica: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    ilustraciones: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    diploma: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    etiqueta: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    cv: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    membretada: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    portadaface: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    menu: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    remision: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    sello: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    senaletica: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    triptico: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
    discos: {
        title: '',
        detail: '',
        images: [],
        prices: [],
    },
};

export { dataCards, servicesDetail };