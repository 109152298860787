import React from 'react';
import './contacto.css';
import img_facebook from '../../assets/facebook.svg'
import img_instagram from '../../assets/instagram.svg'
import linkToPdfAP  from '../../assets/AvisoPrivacidad/Aviso de Privacidad Integral.2023.12.15.pdf';
import linkToPdfTC  from '../../assets/AvisoPrivacidad/T&C.2024.01.18.pdf';
const Contacto = () => {
    return (
        <section id='contacto' className='contact-section'>
            <h1>Contáctanos</h1>
            <h2>En nuestras redes sociales</h2>
            <div className='social-container'>
                <a href='https://www.facebook.com/IntellegniumDisenadores'>
                    <img src={img_facebook} target="_blank" alt="facebook" className='social-image' />
                </a>
                <a href='https://www.instagram.com/IntellegniumDisenadores'>
                    <img src={img_instagram} target="_blank" alt="facebook" className='social-image' />
                </a>
            </div>
            <div>
                <a href={linkToPdfAP} target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a>
            </div>
            <div>
                <a href={linkToPdfTC} target="_blank" rel="noopener noreferrer">Terminos y Condiciones</a>
            </div>
        </section>
    );
}

export default Contacto;